import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['render']
    static values = {
        endpoint: String,
        data: Object,
    }

    connect() {
        
        // Data returned from endpoint must match the requirements for highcharts usage in the render function
        if(this.endpointValue !== ""){
            let callbackClass = this;
            $.ajax({
                type: 'GET',
                url:  this.endpointValue,
                dataType: "json",
                success(res){
                    callbackClass.render(res)
                },
                error: function() {
                    callbackClass.renderError("Failed to retrieve series data, please contact Adlumin support")
                }
            });
        }else{
            // For passing raw data in a stimulus attribute instead of getting from an endpoint
            this.render(this.dataValue)
        }
    }

    render(data) {
        let {title, xAxis, yAxis, series} = data;
        let opts = {
            title: title ? title: null,
            chart: {
                type: "line"
            },
            credits: { enabled: false },
            xAxis: xAxis,
            yAxis: yAxis,
            series: series,
            exporting: { enabled: false },
        };
        new Highcharts.chart(this.renderTarget, opts)
    }

    renderError(message) {
        let errorMessage = $(`<h6>${message}</h6>`)
        $(this.renderTarget).append(errorMessage)
    }
}