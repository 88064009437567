import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
 static targets = [ 'csvLink']
  static values = {
    url: String
  }
  connect() {
  }
  download() {
    window.location.href = this.urlValue
  }
}
