import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['table']
    static values = { ajaxUrl: String, columns: Array }

    connect() {
      this.render()
    }

  render() {
    const controller = this

    $(controller.tableTarget).DataTable({
      ajax: controller.ajaxUrlValue,
      columns: controller.columnsValue,
      dom: '<"top">tr<"bottom"<"d-flex justify-content-between align-items-center border-top pt-3"<""i><"d-flex align-items-center"lp>>><"clear">',
      lengthMenu: [10, 30, 50, 100],
      orderCellsTop: false,
      ordering: false,
      pageLength: 50,
      processing: true,
      searching: false,
      serverSide: true
    })
  }
}
