import { application } from "./application"
import ContentLoader from 'stimulus-content-loader'
import Clipboard from 'stimulus-clipboard'
import AttributeChangerController from "./attribute_changer_controller";
import AtRiskExemptionsController from "./at_risk_exemptions_controller";
import AppNavigationController from './app_navigation_controller'
import CloseMobileSidebarController from './close_mobile_sidebar_controller'
import CVMDatatableController from './cvm_datatable_controller'
import AjaxContentReplacerController from "./ajax-content-replacer-controller";
import DatatableController from './datatable_controller'
import BargraphController from './cvm_bargraph_controller'
import BubblegraphController from './bubblegraph_controller'
import LinegraphController from './linegraph_controller'
import CVMKpiController from './cvm_kpi_controller'
import DateRangePickerController from './date_range_picker_controller'
import HighchartsBarController from './highcharts_bar_controller'
import HighchartsColumnController from './highcharts_column_controller'
import HighchartsDonutController from './highcharts_donut_controller'
import HighchartsMapController from './highcharts_map_controller'
import HighchartsLineController from './highcharts_line_controller'
import MdbAutocompleteController from './mdb_autocomplete_contoller'
import MdbInitializerController from './mdb_initializer_controller'
import SectionRevealerController from './section_revealer_controller'
import SelectizeController from './selectize_controller'
import SidebarController from './sidebar_controller'
import StaleItemsExemptions from './stale_items_exemptions_controller'
import TabSwitcherController from './tab_switcher_controller'
import TinyMceController from './tiny_mce_controller'
import ModalFormController from './modal_form_controller'
import SalesforceController from '../../controllers/salesforce_controller'
import IntegrationGeneratorController from './integration_generator_controller'
import IntegrationSyncController from './integration_sync_controller'
import PartnerFilterController from './partner_filter_controller'
import MdroverviewController from './mdroverview_controller'
import IntegrationBookmarksController from './integration_bookmarks_controller'
import TrdLineGraphController from './trd/line_graph_controller'
import TrdBarGraphController from './trd/bar_graph_controller'
import TrdBubbleGraphController from './trd/bubble_graph_controller'
import RansomwareDefenseDatatableController from './trd/ransomware_defense_datatable_controller'
import CsvController from './csv_controller'
import RansomwarePreventionDatatableController from './ransomware_prevention_datatable_controller'
import PenTestDatatableController from './pen_test/datatable_controller'
import PenTestFormSubmitterController from './pen_test/form_submitter_controller'
import PenTestLineGraphController from './pen_test/line_graph_controller'
import FormInputsController from './form_inputs_controller'

application.register('content-loader', ContentLoader)
application.register('clipboard', Clipboard)
application.register('attribute-changer', AttributeChangerController)
application.register('at-risk-exemptions', AtRiskExemptionsController)
application.register('app-navigation', AppNavigationController)
application.register('close-mobile-sidebar', CloseMobileSidebarController)
application.register('cvm-datatable', CVMDatatableController)
application.register('cvm-bargraph', BargraphController)
application.register('bubblegraph', BubblegraphController)
application.register('linegraph', LinegraphController)
application.register('cvm-kpi-change', CVMKpiController)
application.register('ajax-content-replacer', AjaxContentReplacerController)
application.register('datatable', DatatableController)
application.register('date-range-picker', DateRangePickerController)
application.register('highcharts-bar', HighchartsBarController)
application.register('highcharts-column', HighchartsColumnController)
application.register('highcharts-donut', HighchartsDonutController)
application.register('highcharts-map', HighchartsMapController)
application.register('highcharts-line', HighchartsLineController)
application.register('mdb-autocomplete', MdbAutocompleteController)
application.register('mdb-initializer', MdbInitializerController)
application.register('section-revealer', SectionRevealerController)
application.register('selectize', SelectizeController)
application.register('sidebar', SidebarController)
application.register('tab-switcher', TabSwitcherController)
application.register('tiny-mce', TinyMceController)
application.register('modal-form', ModalFormController)
application.register('salesforce', SalesforceController)
application.register('stale-items-exemptions', StaleItemsExemptions)
application.register('integration-generator', IntegrationGeneratorController)
application.register('integration-sync', IntegrationSyncController)
application.register('partner-filter', PartnerFilterController)
application.register('mdroverview', MdroverviewController)
application.register('integration-bookmarks', IntegrationBookmarksController)
application.register('trd-line-graph', TrdLineGraphController)
application.register('trd-bar-graph', TrdBarGraphController)
application.register('trd-bubble-graph', TrdBubbleGraphController)
application.register('ransomware-defense-datatable', RansomwareDefenseDatatableController)
application.register('csv', CsvController)
application.register('ransomware-prevention-datatable', RansomwarePreventionDatatableController)
application.register('pen-test-datatable', PenTestDatatableController)
application.register('pen-test-form-submitter', PenTestFormSubmitterController)
application.register('pen-test-line-graph', PenTestLineGraphController)
application.register('form-inputs', FormInputsController)
