import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form", "partner", "chip"]

    addFilter(event) {
        // add partner filter badge to datatable
        let selection = document.getElementById("partner_filter_pills")
        
        selection.innerHTML = `<div class="chip" data-mdb-close="true" data-partner-filter-target="chip">
            ${this.partnerTarget.selectedOptions[0].label}
            <i class="close fas fa-times" data-action="click->partner-filter#clearFilter"></i></div>`

        let currentTable = $($.fn.dataTable.tables({ visible: true })[0]).DataTable();

        $.ajax({
            method: 'GET',
            type: 'GET',
            url: location.href,
            dataType: "json",
            context: this,
            data: { partner_id: this.partnerTarget.selectedOptions[0].value },
            success: function(data){
                $("#abakis-filter select").val($("#abakis-filter select option:first").val(""));
                $('button#filter-button-submit').addClass('disabled').attr('disabled', 'disabled')
                $("#partnerFilterModal").modal('hide');
                // update the source url to have the partner_id, and load the table
                if (data.partner_id) {
                    const newUrl = `${currentTable.ajax.url()}?partner_id=${data.partner_id}`;
                    currentTable.ajax.url(newUrl).load();
                  }
            },
            error: function (xhr, status, error) {
                console.log('Error filtering Abakis console 1 by partner: ' + xhr.responseText + ", Error: " + error)
            }
        })

    }

    clearFilter(event) {
        // remove chips and redraw the table
        if (this.hasChipTarget){
            this.chipTarget.remove();
            $.ajax({
                method: 'GET',
                type: 'GET',
                url: location.href,
                dataType: "json",
                success: function (data) {
                    // set the source url back to the default url
                    let currentTable = $($.fn.dataTable.tables({ visible: true })[0]).DataTable(); //whichever datatable is currently showing
                    const originalUrl = currentTable.ajax.url().split('?')[0];
                    currentTable.ajax.url(originalUrl).load();
                },
                error: function (xhr, status, error) {
                    console.log('Error filtering Abakis console 2 by partner: ' + xhr.responseText + ", Error: " + error)
                }
            })
        }
    }


}